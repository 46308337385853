
import { instance } from "./api/instance";

// TODO: remove if `post_verification_check` works fine
export const post_verification_check___ = (application, otp, agreed_to_additional_products = false, seon_payload = null, juicy_session_id = null, latitude = null, longitude = null, accuracy = null, ip_address = null) => instance.post(`/api/v1/verification/check`, { application: application, otp: otp, agreed_to_additional_products:agreed_to_additional_products}, { headers: { Latitude: latitude, Longitude: longitude, Coordinate_accuracy: accuracy, IP_Address: ip_address, Seon_Payload: seon_payload, Juicy_Session_ID: juicy_session_id } });//.then((res) => res.data).catch(error => {console.error(error);});

// TODO: remove if `post_verification_check` works fine
export const post_verification_check_2 = (requestBodyObject, requestHeaderObject) => instance.post(`/api/v1/verification/check`, { 
    application: requestBodyObject.application, 
    otp: requestBodyObject.otp, 
    agreed_to_additional_products:requestBodyObject.agreed_to_additional_products
}, { headers: { 
    Latitude: requestHeaderObject.latitude,
    Longitude: requestHeaderObject.longitude, 
    Coordinate_accuracy: requestHeaderObject.accuracy, 
    IP_Address: requestHeaderObject.ip_address, 
    Seon_Payload: requestHeaderObject.seon_payload, 
    Juicy_Session_ID: requestHeaderObject.juicy_session_id } });//.then((res) => res.data).catch(error => {console.error(error);});

export const post_verification_check = (requestBodyObject, requestHeaderObject) => instance.post(`/api/v1/verification/check`, requestBodyObject, { headers: requestHeaderObject});
export const get_phone_number_verification_long_umid_and_privacy_policy_links = (umid_str) => instance.get(`/api/v1/phone-number-verification-result/${umid_str}`);