import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Sheet from 'react-modal-sheet';
import parse from 'html-react-parser';
import OtpInput from 'react-otp-input';
import { z } from 'zod';
import './App.css';
import { TailSpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import {
  post_verification_check,
  get_phone_number_verification_long_umid_and_privacy_policy_links,
} from './AppHttpCalls';

import { instance } from "./api/instance";
import {
  TIMEOUT_MS,
  RETRY_INTERVAL_MS,
  OTP_LENGTH,
  SEPARATOR,
  UMI_BLUE_COLOR,
  IP_SERVICE_URL,
  AgreementTypesEnum,
  AGREEMENT_ORDER,
  FAIL_KEYWORD,
  AgreementResponseSchema,
  VerificationCheckRequestSchema,
  VerificationCheckResponseSchema,
  VerificationCheckRequestHeaderSchema
} from './AppValidations'

// Collect user's geodata
function usePosition() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [accuracy, setAccuracy] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      setAccuracy(position.coords.accuracy);
    });
  }, []);

  return { latitude, longitude, accuracy };
}

// Collect user's IP address
function useIPAddress() {
  const [ipAddress, setIPAddress] = useState(null);

  useEffect(() => {
    const fetchIP = async () => {
      const response = await fetch(IP_SERVICE_URL);
      const data = await response.json();
      setIPAddress(data.ip);
    };

    if (!ipAddress) {
      fetchIP();
    }
  }, [ipAddress]);

  return ipAddress;
}

// Collect user's activity tracker provided by JuicyScore third-party service
function useJuicySession(juicyScoreApi) {
  const [juicySession, setJuicySession] = useState(null);

  useEffect(() => {
    if (!juicySession) {
      juicyScoreApi.getSessionId().then(setJuicySession);
      window.addEventListener('juicy.score.session.complete', () => {
        window.jslabApi.getSession();
      });
    }
  }, [juicySession, juicyScoreApi]);

  return juicySession;
}

// Collect user's activity tracker provided by Seon third-party service
function useSeonPayload(umidLong) {
  const [seonPayload, setSeonPayload] = useState('');

  useEffect(() => {


    if (umidLong !== "" && seonPayload === "") {
      try {
        window.seon.config(atob(umidLong));
        window.seon.getBase64Session().then(setSeonPayload).catch(console.warn);
      } catch (e) {
        console.warn('No seon', e);
      }
    }
  }, [umidLong, seonPayload]);

  return seonPayload;
}

// Wait for user's tracking data to be collected in order to hide tailspin and display actual page
function waitForParameters(callback, juicySession, seonPayload, timeoutMs) {
  const startTime = Date.now();

  const checkParameters = () => {
    if (juicySession && seonPayload) {

      callback();
    } else if (Date.now() - startTime > timeoutMs) {

      callback();
    } else {
      setTimeout(checkParameters, RETRY_INTERVAL_MS);
    }
  };

  checkParameters();
}

function App() {
  const [otp, setOtp] = useState('');
  const [isPageLoadSpinVisible, setPageLoadSpinVisible] = useState(true);
  const [isTailSpinVisible, setTailSpinVisible] = useState(false);
  const [xMarkDisplayMode, setXMarkDisplayMode] = useState(false);
  const [isGotAttempts, setGotAttempts] = useState(true);
  const [checkMarkDisplayMode, setCheckMarkDisplayMode] = useState(false);
  const [failReasonText, setFailReasonText] = useState('');
  const [failAttemptsText, setFailAttemptsText] = useState('');

  const [isPageDataFetchingError, setPageDataFetchingError] = useState(false);


  const searchParams = new URLSearchParams(document.location.search);
  const umidShort = searchParams.get('v') || document.location.pathname.replace('/', '');

  const { latitude, longitude, accuracy } = usePosition();
  const ipAddress = useIPAddress();
  const juicySession = useJuicySession(window.juicyScoreApi);
  // const [seonPayload, setSeonPayload] = useState('');

  const [umidLong, setUmidLong] = useState('');
  const seonPayload = useSeonPayload(umidLong);


  const [agreements, setAgreements] = useState([]);
  const [linkClickedStates, setLinkClickedStates] = useState({});
  const [checkBoxStates, setCheckBoxStates] = useState(Object.values(AgreementTypesEnum).reduce((acc, type) => {
    acc[type] = false;
    return acc;
  }, {}));
  const [contentStates, setContentStates] = useState({});


  useEffect(() => {
    const fetchInitialData = async () => {
      if (umidLong === "") {
        try {
          const agreementResponse = await get_phone_number_verification_long_umid_and_privacy_policy_links(umidShort);
          const parsedAgreementData = AgreementResponseSchema.parse(agreementResponse.data);

          setUmidLong(parsedAgreementData.umid_long);
          setAgreements(parsedAgreementData.agreements);


          const initiallinkClickedStates = {};
          const initialContentStates = {};


          for (const agreement of parsedAgreementData.agreements) {
            if (AgreementTypesEnum[agreement.type]) {
              initiallinkClickedStates[agreement.type] = false; // Initially unchecked
              setCheckBoxStateTrue(agreement.type);
              // Fetch HTML content for each link
              const response = await instance.get(agreement.link);
              initialContentStates[agreement.type] = response.data; // Store HTML content
            }
          }



          setLinkClickedStates(initiallinkClickedStates);
          setContentStates(initialContentStates);

        } catch (error) {
          if (error instanceof z.ZodError) {
            console.error('Validation failed:', error.errors);
          } else {
            setPageDataFetchingError(true);
            console.error('Error fetching privacy policy link:', error);
          }
        }
      }
    };

    if (umidLong === "") {
      fetchInitialData();
    }

    waitForParameters(() => setPageLoadSpinVisible(false), juicySession, seonPayload, TIMEOUT_MS);
  }, [umidLong]);

  const handleLinkClick = (type) => {
    setLinkClickedStates((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleInputChange = (type, value) => {

    setCheckBoxStates(prevState => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  };


  const setCheckBoxStateTrue = (key) => {
    setCheckBoxStates((prevState) => ({
      ...prevState,
      [key]: true, // Set the specific key to true
    }));
  };

  const renderAgreementWidget = (agreement) => {
    switch (agreement.type) {
      case AgreementTypesEnum.CLIENT_CONTINUATION_ORDER:
        return (
          <div className="content centered">
            <div className="view">
              <span id={`span-policy-${agreement.type}`} className='grey'>
                By entering OTP code you acknowledge you have read and agreed to{' '}
                <a href="#" onClick={() => handleLinkClick(agreement.type)}>
                  Data Privacy Policy
                </a>
              </span>
            </div>
          </div>
        );
      case AgreementTypesEnum.CLIENT_PRIVACY_POLICY:
        return (
          <div className="content centered">
            <div className="view">

              <span id={`span-policy-${agreement.type}`} className='grey'>
                By entering OTP code you acknowledge you have read and agreed to{' '}
                <a href="#" onClick={() => handleLinkClick(agreement.type)}>
                  Data Privacy Policy
                </a>
              </span>
            </div>
          </div>
        );
      case AgreementTypesEnum.CLIENT_ADDL_PRODUCTS:
        return (
          <div className="content">
            <div className="view">
              <input
                type="checkbox"
                id={`checkbox-${agreement.type}`}
                checked={checkBoxStates[agreement.type]}
                // onChange={() => handleLinkClick(agreement.type)}
                onChange={() => handleInputChange(agreement.type)}
              />
              <span id={`span-policy-${agreement.type}`} className='grey'>
                I hereby consent to subscribe to insurance: {' '}
                <a href="#" onClick={() => handleLinkClick(agreement.type)}>
                  Terms and Conditions
                </a>
              </span>
            </div>
          </div>
        );
      default:
        return null; // For unknown types, render nothing
    }
  };

  // Function to render widgets based on ENUM type
  const renderAgreementWidgetsWithBottomSheet = () => {

    // Sort agreements in a specific expected way to display on page in correct order
    const sortedAgreements = agreements.slice().sort((a, b) => {
      const orderA = AGREEMENT_ORDER.indexOf(a.type);
      const orderB = AGREEMENT_ORDER.indexOf(b.type);
      return orderA - orderB;
    });

    // Render components for each agreeement type
    return sortedAgreements.map((agreement) => {
      if (AgreementTypesEnum[agreement.type]) {
        return (
          <div key={agreement.type}>
            {renderAgreementWidget(agreement)}
            <Sheet
              isOpen={linkClickedStates[agreement.type]}
              onClose={() => handleLinkClick(agreement.type)}
            >
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                  <div className="close-button-container">
                    <input
                      type="button"
                      className='close-button'
                      id={`close-sheet-${agreement.type}`}
                      onClick={() => handleLinkClick(agreement.type)}
                      value="×"
                      autoFocus
                    />
                  </div>
                  <Sheet.Scroller draggableAt="both">{parse(contentStates[agreement.type])}</Sheet.Scroller>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          </div>
        );
      }
      return null; // Skip types that are not in AgreementTypesEnum
    });
  };

  const renderFailPageWidgets = () => {

    return (
      <div key={AgreementTypesEnum.CLIENT_PRIVACY_POLICY.value}>
        <div className="content centered">
          <div className="view">

            <span id={`span-policy-${AgreementTypesEnum.CLIENT_PRIVACY_POLICY.value}`} className='grey'>
              By entering OTP code you acknowledge you have read and agreed to{' '}
              <a href="#" onClick={() => handleLinkClick(FAIL_KEYWORD)}>
                Data Privacy Policy
              </a>
            </span>
          </div>
        </div>

        <Sheet
          isOpen={linkClickedStates[FAIL_KEYWORD]}
          onClose={() => handleLinkClick(FAIL_KEYWORD)}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className="close-button-container">
                <input
                  type="button"
                  className='close-button'
                  id={`close-sheet-${AgreementTypesEnum.CLIENT_PRIVACY_POLICY.value}`}
                  onClick={() => handleLinkClick(FAIL_KEYWORD)}
                  value="×"
                  autoFocus
                />
              </div>
              <Sheet.Scroller draggableAt="both">

                <div className='content centered'>
                  <p>
                    <span>
                      <h2>
                        Data Privacy Policy
                      </h2>
                    </span>
                  </p>
                  <p>
                    <span>
                      Contract is not available. Contact the agent to solve the problem.

                    </span>
                  </p>
                </div>

              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    );

  }


  const handleOtpChange = (value) => {
    setOtp(value);
    if (value.length === OTP_LENGTH) {
      setXMarkDisplayMode(false);
      setFailReasonText('');
      setFailAttemptsText('');
      setCheckMarkDisplayMode(false);
      setTailSpinVisible(true);

      waitForParameters(() => {
        const verificationCheckRequestBody = VerificationCheckRequestSchema.parse({ application: umidLong, otp: value, agreed_to_additional_products: checkBoxStates[AgreementTypesEnum.CLIENT_ADDL_PRODUCTS] })
        const verificationCheckRequestHeader = VerificationCheckRequestHeaderSchema.parse({ Seon_Payload: seonPayload, Juicy_Session_ID: juicySession, Latitude: latitude, Longitude: longitude, Coordinate_accuracy: accuracy, IP_Address: ipAddress })
        post_verification_check(verificationCheckRequestBody, verificationCheckRequestHeader)
          .then(() => {
            setCheckMarkDisplayMode(true);
            setTailSpinVisible(false);
          })
          .catch((error) => {
            setCheckMarkDisplayMode(false);
            setXMarkDisplayMode(true);
            setTailSpinVisible(false);
            const responseDataParsed = VerificationCheckResponseSchema.parse(error.response.data);
            const attemptsLeft = responseDataParsed.otp_check_count_max - responseDataParsed.otp_check_count;
            setGotAttempts(attemptsLeft > 0);
            setFailAttemptsText(
              `${attemptsLeft} attempts remaining`
            );
          });
      }, juicySession, seonPayload, TIMEOUT_MS);
    }
  };

  return (
    <div className="App">

      {isPageLoadSpinVisible && (
        <div className="fullscreen-v-centered">
          {/* <TailSpin height="40%" width="100%" color={UMI_BLUE_COLOR} ariaLabel="tail-spin-loading" radius="4" /> */}
          <TailSpin height="30dvh" width="20dvw" color={UMI_BLUE_COLOR} ariaLabel="tail-spin-loading" radius="4" />
        </div>
      )}

      {!isPageLoadSpinVisible && !checkMarkDisplayMode && isGotAttempts && (
        <div className="content centered">
          <h2>Data Privacy Policy</h2>
          <div className="content">
            {!isPageDataFetchingError && renderAgreementWidgetsWithBottomSheet()}
            {isPageDataFetchingError && renderFailPageWidgets()}
          </div>

          <div className="container centered">
            {/* <div className="content centered"> */}

            {!checkMarkDisplayMode && (
              <div className="content centered">
                <div className="view v-centered">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={OTP_LENGTH}
                    renderSeparator={<span>{SEPARATOR}&nbsp;&nbsp;</span>}
                    inputType="number"
                    shouldAutoFocus={otp.length == 0}
                    renderInput={(props) => <input {...props} />}
                  />

                  <div className="view centered">
                    <div className='user-output'>
                      <TailSpin height="30" width="30" color={UMI_BLUE_COLOR} ariaLabel="tail-spin-loading" radius="1" visible={isTailSpinVisible} />
                    </div>
                  </div>

                  {/* Failed but still got attempts */}
                  {xMarkDisplayMode && (
                    <div className="xMark centered">
                      <FontAwesomeIcon icon={faCircleXmark} style={{ color: 'red' }} />
                    </div>
                  )}
                  {failReasonText && <div className="view error-text centered">{failReasonText}</div>}
                  {failAttemptsText && <div className="view error-text centered">{failAttemptsText}</div>}
                </div>
              </div>
            )}
          </div>
        </div>

      )}


      {/* Success */}
      {checkMarkDisplayMode && (
        <div className="content v-centered">
          <div className="checkMark centered">
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: UMI_BLUE_COLOR }} />
          </div>
          <br />
          <h3>
            OTP code is correct
          </h3>
          <p className='grey'>
            Your content has been recorded for control purposes and you can now proceed with  your loan application
          </p>
        </div>
      )}

      {/* Failed no more attempts */}
      {!isGotAttempts && (
        <div className="content v-centered">
          <div className="xMark centered">
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: 'red' }} />
          </div>
          <br />
          <h3>
            OTP code is incorrect
          </h3>
          <p className='grey'>
            You have exceeded the allowed number of attempts. Your order will be cancelled
          </p>
        </div>
      )}

    </div>
  );
}

App.propTypes = {
  umidShort: PropTypes.string,
};

export default App;
