import { z } from 'zod';



export const TIMEOUT_MS = 5000;
export const RETRY_INTERVAL_MS = 1000;
export const OTP_LENGTH = 4;
export const SEPARATOR = ' ';
// export const UMI_BLUE_COLOR = '#0162ff';
export const UMI_BLUE_COLOR = '#0084F4';
export const IP_SERVICE_URL = 'https://ipapi.co/json/';
export const FAIL_KEYWORD = 'fail';


// --- Verification schemas for /phone-number-verification-result/${umid_str} get request ---

// Enum of expected agreements.type values in expected response
export const AgreementTypesEnum = {
  CLIENT_PRIVACY_POLICY: 'CLIENT_PRIVACY_POLICY',
  CLIENT_CONTINUATION_ORDER: 'CLIENT_CONTINUATION_ORDER',
  CLIENT_ADDL_PRODUCTS: 'CLIENT_ADDL_PRODUCTS'
};

// The correct order for agreement types to be rendered on page
export const AGREEMENT_ORDER = [
  AgreementTypesEnum.CLIENT_PRIVACY_POLICY,
  AgreementTypesEnum.CLIENT_CONTINUATION_ORDER,
  AgreementTypesEnum.CLIENT_ADDL_PRODUCTS
];

// Inner object validation schema for get /phone-number-verification-result/${umid_str}
export const AgreementSchema = z.object({
  link: z.string().url(),
  type: z.enum(Object.values(AgreementTypesEnum)),
});

// Validation schema for get /phone-number-verification-result/${umid_str}
export const AgreementResponseSchema = z.object({
  umid_long: z.string(),
  agreements: z.array(AgreementSchema),
});


// --- Verification schemas for /varivication/chack post request ---

// Validation schema for request body on /verification/check
export const VerificationCheckRequestSchema = z.object({
    otp: z.string(),
    application: z.string(),
    agreed_to_additional_products: z.boolean().optional().default(false),

});

// Validation schema for response body on /verification/check
export const VerificationCheckResponseSchema = z.object({
    is_otp_verified: z.boolean().catch(false),
    otp_check_count: z.number().nullable().default(0).catch(0),
    otp_check_count_max: z.number().nullable().default(0).catch(0),
    detail: z.string().optional(),

    
});

// Validation schema for headers on /verification/check
export const VerificationCheckRequestHeaderSchema = z.object({

    Latitude: z.number().nullable(),
    Longitude: z.number().nullable(),
    Coordinate_accuracy: z.number().nullable(),
    IP_Address: z.string().optional(),
    Seon_Payload: z.string().optional(),
    Juicy_Session_ID: z.string().optional(),

});

// --- Rendering constants ---

// export const errorAgreementWidget = (
//         <div className="content centered">
//             <div className="view">

//         <span id={`span-policy-failed`}>
//         By entering OTP code you acknowledge you have read and agreed to{' '}
//         <a href="#" onClick={() => handleLinkClick(agreement.type)}>
//         Data Privacy Policy
//         </a>
//     </span>
//     </div>
//     </div>
//     );

// export errorBorromSheetContent = null;

// export const errorBottomSheet = null;